import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import { setAuthStatus } from './actions/authAction';
import { setProperties } from './actions/propertyAction';
import store from './store';
import HomeV3 from './components/home-v3';
import About from './components/about';
import Error from './components/404';
import Shop from './components/shop';
import ProdductDetails from './components/product-details';
import Contact from './components/contact';
import Login from './components/login';
import Manage from './components/manage';
import Gallery from './components/gallery';
import { apiService } from './apiService';
import ProtectedRoute from './protectedRoute';

class Root extends Component {
    componentDidMount() {
        this.checkAuthStatus();
        this.checkProperties();
    }

    checkAuthStatus = async () => {
        try {
            const result = await apiService.get('/User/validate-token');
            this.props.setAuthStatus(result.isAuthenticated);
        } catch (error) {
            this.props.setAuthStatus(false);
        }
    };

    checkProperties = async () => {
        if (!this.props.properties || this.props.properties.length === 0) {
            try {
                const data = await apiService.get('/Property');
                this.props.setProperties(data);
            } catch (error) {
            }
        }
    };

    render() {
        return (
            <HashRouter basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV3} />
                        <Route path="/about" component={About} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/404" component={Error} />
                        <Route path="/properties" component={Shop} />
                        <Route path="/property-details/:propertyId" component={ProdductDetails} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/login" component={Login} />
                        <ProtectedRoute path="/manage" component={Manage} />
                    </Switch>
                </div>
            </HashRouter>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    properties: state.property.properties
});

const mapDispatchToProps = dispatch => ({
    setAuthStatus: (isAuthenticated) => dispatch(setAuthStatus(isAuthenticated)),
    setProperties: (properties) => dispatch(setProperties(properties))
});

const ConnectedRoot = connect(mapStateToProps, mapDispatchToProps)(Root);

export default ConnectedRoot;

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRoot />
    </Provider>,
    document.getElementById('quarter')
);
