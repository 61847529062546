import React, { useState, useEffect } from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import PortfolioV1 from './section-components/portfolio-v1';
import Footer from './global-components/footer';
import History from './section-components/history';
import { Helmet } from 'react-helmet';
import { apiService } from '../apiService';
import CallToActonV1 from './section-components/call-to-action-v1';

const Gallery = () => {
    const [media, setMedia] = useState([]);

    useEffect(() => {
        const getMedia = async () => {
            try {
                const response = await apiService.get('/Media/gallery-media');
                const data = response; // Assuming response structure has a 'data' field
                setMedia(data);
            } catch (error) {
                setMedia([]); // Set to empty array on error to avoid null checks later
            }
        };

        getMedia();
    }, []);

    const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

    return <div>
        <Helmet>
            <title>Explore The Island - A Visual Journey through Long Beach Island, NJ | LBI4RENT</title>
            <meta name="description" content="Embark on a visual journey through Long Beach Island, NJ with our exclusive photo gallery. Discover the stunning landscapes, vibrant community, and serene beaches that make LBI a premier vacation destination." />
            <meta name="keywords" content="Long Beach Island gallery, LBI photos, explore LBI, NJ beach photos, Long Beach Island scenery" />
            <link rel="canonical" href="https://www.lbinj4rent.com/#/gallery" />
            {/* Open Graph tags for social media */}
            <meta property="og:title" content="Explore The Island - Discover Long Beach Island, NJ | LBI4RENT" />
            <meta property="og:description" content="Take a visual tour of Long Beach Island, NJ through our photo gallery. Experience the beauty, culture, and unique atmosphere that make LBI a beloved vacation spot." />
            <meta property="og:url" content="https://www.lbi4rent.com/#/gallery" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={IMAGE_BASE_URL + "/LBI4RENT-Images/1/maing.jpg"} /> {/* Replace URL_TO_A_FEATURED_IMAGE_FROM_THE_GALLERY with the actual URL of a standout image from your gallery */}
            <meta property="og:site_name" content="LBI4RENT" />
            <meta property="og:see_also" content="https://www.facebook.com/Lbi4rent" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle="The Island" />
        {/* <History /> */}
        <PortfolioV1 media={media} />
        <CallToActonV1 />
        <Footer />
    </div>
}

export default Gallery
