import React, { Component } from 'react';

class AboutV4 extends Component {
	state = {
		isPlaying: true
	};

	togglePlay = () => {
		this.setState(prevState => ({
			isPlaying: !prevState.isPlaying
		}));
	}

	render() {
		const { isPlaying } = this.state;
		const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={IMAGE_BASE_URL + "/LBI4RENT-Images/sideways_bay.JPG"} alt="About Us Image" />
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
								<h1 className="section-title">Long Beach Island, NJ's Premier Destination for Vacation Rentals<span>.</span></h1>
								<p>Six unique homes including two of the most stupendous 5 bedroom oceanfront rental homes on Long Beach Island (LBI), NJ are offered right here directly from the owner, Chris Georgiou. The homes cover a range from easily affordable to ultimate Luxury ($2,000 to $11,000 per week).

									Six Great homes, six great locations. Each home offers its unique amenities and overal vibe. These homes are offered for rent weekly (Saturday to Saturday) in season from June to September. They are also offerred for rent in the off season from October to May (monthly, weekly or for extended get away weekends) including Memorial Day weekend, Thanksgiving , Christmas and New Years Holidays.

									Long Beach Island is the ideal getaway for singles, couples, families and groups alike. With 18 miles of beautiful beaches, plenty of elegant dinning, casual summer eateries, kid friendly activities, Fantasy Island, and water activities, LBI is the place to vacation this year. LBI is close to Atlantic City with its World Class Casinos and Famous Boardwalk. LBI is easily accessable from Route 72 via the Garden State Parkway or Rt 539.

									Contact us by email at info@lbi4rent.com or call us at (609) 587-7671 or (609) 462-8330 cell for further information or to reserve a week, extended weekend or longer.</p>
							</div>
							{/* <ul className="ltn__list-item-half clearfix">
								<li>
									<i className="flaticon-home-2" />
									Smart Home Design
								</li>
								<li>
									<i className="flaticon-mountain" />
									Beautiful Scene Around
								</li>
								<li>
									<i className="flaticon-heart" />
									Exceptional Lifestyle
								</li>
								<li>
									<i className="flaticon-secure" />
									Complete 24/7 Security
								</li>
							</ul> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV4