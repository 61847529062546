import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TeamV1 extends Component {

	render() {
		var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__team-area pt-115 pb-90 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h1 className="section-title">Property Owner</h1>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-4 col-sm-6">
						<div className="ltn__team-item ltn__team-item-3---">
							<div className="team-img">
								<img src={IMAGE_BASE_URL + "/LBI4RENT-Images/chris.jpg"} alt="Image" />
							</div>
							<div className="team-info">
								<h4><Link to="/team-details">Chris Georgiou</Link></h4>
								<div className="ltn__social-media">
									<ul>
										<li><a href="https://www.facebook.com/georgioucc"><i className="fab fa-facebook-f" /></a></li>
										<li><a href="https://www.linkedin.com/in/chris-georgiou-825a27b/"><i className="fab fa-linkedin-in" /></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default TeamV1