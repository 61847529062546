import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import { Redirect } from 'react-router-dom';
import { apiService } from '../../apiService';
import { connect } from 'react-redux';
import { setAuthStatus } from '../../actions/authAction';

class Footer_v1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectToHome: false,
		};
	}

	componentDidMount() {

		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

			$(window).scrollTop(0);

			setTimeout(function () {
				$(".quarter-overlay").fadeOut(300);
			}, 800);

		});


		$(document).on('click', '.theme-btn-1 ', function () {
			$('div').removeClass('modal-backdrop');
			$('div').removeClass('show');
			$('div').removeClass('fade');
			$('body').attr("style", "");
		});
	}

	logout = async () => {
		try {
			await apiService.logout();
			this.props.setAuthStatus(false);
			this.setState({ redirectToHome: true });
		} catch (error) {
		}
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		if (this.state.redirectToHome) {
			window.location.href = "/";
		}
		const { isAuthenticated } = this.props;


		return (
			<footer className="ltn__footer-area  ">
				<div className="footer-top-area  section-bg-2 plr--5">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xl-3 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-about-widget">
									<div className="footer-logo">
										<div className="site-logo">
											<img src={IMAGE_BASE_URL + "/LBI4RENT-Images/logo-2.png"} alt="Logo" />
										</div>
									</div>
									<div className="footer-address">
										<ul>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<p>Long Beach Island (LBI), New Jersey, USA</p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-call" />
												</div>
												<div className="footer-address-info">
													<p><a href="tel:+16095877671">(609) 587-7671 or (609) 462-8330</a></p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-mail" />
												</div>
												<div className="footer-address-info">
													<p><a href="mailto:info@lbi4rent.com">info@lbi4rent.com</a></p>
												</div>
											</li>
										</ul>
									</div>
									<div className="ltn__social-media mt-20">
										<Social />
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">LBI4RENT</h4>
									<div className="footer-menu go-top">
										<ul>
											<li><Link to="/about">About</Link></li>
											<li><Link to="/gallery">The Island</Link></li>
											<li><Link to="/blog-grid">Properties</Link></li>
											<li><Link to="/contact">Contact</Link></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Admin</h4>
									<div className="footer-menu go-top">
										<ul>
											{isAuthenticated ? (
												<>
													<li><Link to="/manage">Manage</Link></li>
													<li><a href="#" onClick={this.logout}>Logout</a></li>
												</>
											) : (
												<li><Link to="/login">Login</Link></li>
											)}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Copyright />
			</footer>
		)
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthStatus: (isAuthenticated) => dispatch(setAuthStatus(isAuthenticated)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer_v1);