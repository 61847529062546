import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const Shop_V1 = () => {
    const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

    return <div>
        <Helmet>
            <title>Find Your Ideal Beach House Rental in LBI | Property Search - LBI4RENT</title>
            <meta name="description" content="Search, sort, and filter through our extensive list of Long Beach Island beach house rentals to find your perfect vacation home. LBI4RENT offers a variety of properties with luxurious amenities, oceanfront views, and more. Start your LBI adventure today!" />
            <meta name="keywords" content="LBI beach house search, Long Beach Island rentals, find beach house NJ, vacation rentals LBI, property search LBI, filter beach homes" />
            <link rel="canonical" href="https://www.lbinj4rent.com/#/properties" />
            {/* Open Graph tags for social media */}
            <meta property="og:title" content="Search Beach House Rentals in Long Beach Island, NJ | LBI4RENT" />
            <meta property="og:description" content="Easily find your dream beach house rental in Long Beach Island, NJ with LBI4RENT. Use our search, sort, and filter options to discover properties that match your needs. Book your next vacation home today!" />
            <meta property="og:url" content="https://www.lbinj4rent.com/#/properties" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={IMAGE_BASE_URL + "/LBI4RENT-Images/1/maing.jpg"} /> {/* Replace URL_TO_YOUR_FEATURED_PROPERTY_IMAGE with the actual URL of a standout property image or a collage representing the variety of rentals available */}
            <meta property="og:site_name" content="LBI4RENT" />
            <meta property="og:see_also" content="https://www.facebook.com/Lbi4rent" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle="Properties" />
        <ShogGrid />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Shop_V1

