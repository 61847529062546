import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { apiService } from '../../apiService';

class ShopDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			features: null,
			calendarEvents: [],
			loadingFeatures: true,
			loadingReviews: true,
			rating: 0,
			comment: '',
			name: '',
			email: '',
			phone: '',
			saveInfo: false,
		};
	}

	componentDidMount() {
		this.fetchCalendarEvents();
		this.fetchFeatures();
	}

	handleRatingClick = (rating) => {
		this.setState({ rating });

	}

	fetchCalendarEvents = async () => {
		try {
			const propertyId = this.props.property.id;

			let data = await apiService.post("/Calendar/get-events", propertyId)

			if (data != null) {
				const events = data.map(event => ({
					...event,
					start: new Date(event.start),
					end: new Date(event.end),
					title: "Booked",
					color: "red",
				}));
				this.setState({ calendarEvents: events, loading: false });
			}
		} catch (error) {
		}
	}

	fetchFeatures = async () => {
		try {
			const propertyId = this.props.property.id;
			const apiUrl = `/Feature`;

			let data = await apiService.post(apiUrl, { propertyId })

			if (data != null) {
				const features = data.map(feature => ({
					...feature,
					name: feature.name,
				}));
				this.setState({ features: features, loadingFeatures: false });

			}
		} catch (error) {
		}
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const review = {
			rating: this.state.rating,
			comment: this.state.comment,
			name: this.state.name,
			email: this.state.email,
			phone: this.state.phone,
			consent: this.state.saveInfo,
			propertyId: this.props.property.id,
		}

		try {
			const apiUrl = `/Review/post`;
			await apiService.post(apiUrl, review);
		} catch (error) {
		}
	}

	splitDescription = (description) => {
		const sectionTitles = {
			paymentDetails: "Payment Details:",
			cancellationPolicy: "Cancellation Policy:",
			activities: "Activities"
		};

		let currentSectionIndex = 0;
		let nextSectionIndex;
		let mainDescription, paymentDetails, cancellationPolicy, activities;

		// Find the index of the first section title, if present
		const firstSectionIndex = Object.values(sectionTitles).reduce((firstIndex, title) => {
			const index = description.indexOf(title);
			return index !== -1 && (firstIndex === -1 || index < firstIndex) ? index : firstIndex;
		}, -1);

		// Extract main description
		mainDescription = firstSectionIndex !== -1 ? description.slice(0, firstSectionIndex).trim() : description.trim();

		// Extract payment details
		currentSectionIndex = description.indexOf(sectionTitles.paymentDetails);
		if (currentSectionIndex !== -1) {
			nextSectionIndex = Math.min(
				...Object.values(sectionTitles)
					.map(title => {
						const index = description.indexOf(title, currentSectionIndex + 1);
						return index !== -1 ? index : description.length;
					})
			);
			paymentDetails = description.slice(currentSectionIndex + sectionTitles.paymentDetails.length, nextSectionIndex).trim();
		} else {
			paymentDetails = '';
		}

		// Extract cancellation policy
		currentSectionIndex = description.indexOf(sectionTitles.cancellationPolicy);
		if (currentSectionIndex !== -1) {
			nextSectionIndex = Math.min(
				...Object.values(sectionTitles)
					.map(title => {
						const index = description.indexOf(title, currentSectionIndex + 1);
						return index !== -1 ? index : description.length;
					})
			);
			cancellationPolicy = description.slice(currentSectionIndex + sectionTitles.cancellationPolicy.length, nextSectionIndex).trim();
		} else {
			cancellationPolicy = '';
		}

		// Extract activities
		currentSectionIndex = description.indexOf(sectionTitles.activities);
		if (currentSectionIndex !== -1) {
			activities = description.slice(currentSectionIndex + sectionTitles.activities.length).trim();
		} else {
			activities = '';
		}

		return { mainDescription, paymentDetails, cancellationPolicy, activities };
	}

	render() {
		var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		const { property } = this.props;
		const reviews = property.reviews.filter(review => review.isAllowed).sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));;
		const { features, loadingFeatures } = this.state;
		const { mainDescription, paymentDetails, cancellationPolicy, activities } = this.splitDescription(property["description"]);
		const medias = property.medias.sort((a, b) => {
			if (a.isOutside && !b.isOutside) {
				return -1;
			} else if (!a.isOutside && b.isOutside) {
				return 1;
			}
			return 0;
		});;

		const calculateAverageRating = (reviews) => {
			if (!reviews || reviews.length === 0) return 0;

			const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
			return totalRating / reviews.length;
		};

		const averageRating = calculateAverageRating(reviews);

		const renderStarRating = (averageRating) => {
			const stars = [];
			const fullStars = Math.floor(averageRating);
			const hasHalfStar = averageRating - fullStars >= 0.5;

			for (let i = 0; i < fullStars; i++) {
				stars.push(<li key={i}><a href="#"><i className="fas fa-star" /></a></li>);
			}

			if (hasHalfStar) {
				stars.push(<li key="half"><a href="#"><i className="fas fa-star-half-alt" /></a></li>);
			}

			while (stars.length < 5) {
				stars.push(<li key={stars.length}><a href="#"><i className="far fa-star" /></a></li>);
			}

			return stars;
		};

		const parts = mainDescription.split('<br>').map((part, index) => (
			// Render each part, followed by a <br> except for the last part
			<React.Fragment key={index}>
				{part}
				{index !== mainDescription.split('<br>').length - 1 && <br />}
			</React.Fragment>
		));

		const videoMedia = medias.find(media => media.type.startsWith('video/'));

		const videoUrl = videoMedia ? `${IMAGE_BASE_URL}${videoMedia.link}` : '';


		return <div className="ltn__shop-details-area pb-10">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
						<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
							<div className="ltn__blog-meta">
								<ul>
									<li className="ltn__blog-category">
										<Link className="bg-orange" to="#">For Rent</Link>
									</li>
								</ul>
							</div>
							<h1>{property["name"]}</h1>
							<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> {property["neighborhood"]} {property["city"]}</label>
							<h4 className="title-2">Description</h4>
							<p>{parts}</p>
							{paymentDetails.trim() !== ""
								? (
									<>
										<h4 className="title-2">Payment Details</h4>
										<p>{paymentDetails}</p>
									</>
								)
								: null}
							{cancellationPolicy.trim() !== ""
								? (
									<>
										<h4 className="title-2">Cancellation Policy</h4>
										<p>{cancellationPolicy}</p>
									</>
								)
								: null}
							{activities.trim() !== ""
								? (
									<>
										<h4 className="title-2">Activities</h4>
										<p>{activities}</p>
									</>
								)
								: null}
							{
								loadingFeatures
									? null
									: (
										features?.length > 0
											? (
												<>
													<h4 className="title-2">Features</h4>
													<div className="property-detail-info-list section-bg-1 clearfix mb-60">
														<ul>
															{features?.map((feature, index) => (
																<li key={index}><label>{feature.name}</label></li>
															))}
														</ul>
													</div>
												</>
											)
											: null
									)
							}
							{/* <h4 className="title-2">Gallery</h4>
							<div className="ltn__property-details-gallery mb-30">
								<div className="row">
									<div className="col-md-6">
										<a href={medias[0].link} data-rel="lightcase:myCollection">
											<img className="mb-30" src={medias[0].link} alt="Image" />
										</a>
										<a href={medias[1].link} data-rel="lightcase:myCollection">
											<img className="mb-30" src={medias[1].link} alt="Image" />
										</a>
									</div>
									<div className="col-md-6">
										<a href={medias[2].link} data-rel="lightcase:myCollection">
											<img className="mb-30" src={medias[2].link} alt="Image" />
										</a>
									</div>
								</div>
							</div> */}
							{videoUrl !== null && videoUrl !== ''
								? (
									<>
										<h4 className="title-2">Property Video</h4>
										<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60" data-bs-bg={IMAGE_BASE_URL + medias[0].link}
											style={{ backgroundImage: `url(${IMAGE_BASE_URL + medias[0].link})` }}	>
											<a className="ltn__video-icon-2 ltn__video-icon-2-border---"
												href={videoUrl}
												data-rel="lightcase:myCollection">
												<i className="fa fa-play" />
											</a>
										</div>
									</>
								)
								: <></>
							}
							{property.mapLink !== null && property.mapLink !== ''
								? (
									<>
										<h4 className="title-2">Location</h4>
										<div className="property-details-google-map mb-60">
											<iframe src={property.mapLink} width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
										</div>
									</>
								)
								: null}
							<div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner mb-60">
								<h4 className="title-2">Customer Reviews</h4>
								<>
									<div className="product-ratting">
										<ul>
											{renderStarRating(averageRating)}
											<br />
											<li className="review-total"> <a href="#"> ( {reviews?.length} Reviews )</a></li>
										</ul>
									</div>
									<hr />
									<div className="ltn__comment-area mb-30">
										<div className="ltn__comment-inner">
											<ul>
												{reviews?.map((review, index) => (
													<li key={index}>
														<div className="ltn__comment-item clearfix">
															<div className="ltn__commenter-comment">
																<h6>{review.name}</h6>
																<div className="product-ratting">
																	<ul>
																		{Array.from({ length: review.rating }, (_, index) => (
																			<li key={index}><a href="#"><i className="fas fa-star" /></a></li>
																		))}
																	</ul>
																</div>
																<p>{review.comment}</p>
																<span className="ltn__comment-reply-btn">
																	{new Date(review.dateCreated).toLocaleDateString('en-US', {
																		month: 'long',
																		day: 'numeric',
																		year: 'numeric'
																	})}
																</span>
															</div>
														</div>
													</li>
												))}
											</ul>
										</div>
									</div>
									<div className="ltn__comment-reply-area ltn__form-box mb-30">
										<form onSubmit={this.handleSubmit}>
											<h4>Add a Review</h4>
											<div className="mb-30">
												<div className="add-a-review">
													<h6>Your Rating:</h6>
													<div className="product-ratting">
														<ul>
															{[1, 2, 3, 4, 5].map((stars) => (
																<li key={stars} onClick={(e) => e.preventDefault()}>
																	<a href="#">
																		<i
																			className={`${this.state.rating >= stars ? 'fas fa-star' : 'far fa-star'}`}
																			onClick={() => this.handleRatingClick(stars)}
																		/>
																	</a>
																</li>
															))}
														</ul>
													</div>
												</div>
											</div>
											<div className="input-item input-item-textarea ltn__custom-icon">
												<textarea
													placeholder="Type your comments...."
													value={this.state.comment}
													onChange={(e) => this.setState({ comment: e.target.value })}
												/>
											</div>
											<div className="input-item input-item-name ltn__custom-icon">
												<input
													type="text"
													placeholder="Type your name...."
													value={this.state.name}
													onChange={(e) => this.setState({ name: e.target.value })}
												/>
											</div>
											<div className="input-item input-item-email ltn__custom-icon">
												<input
													type="email"
													placeholder="Type your email...."
													value={this.state.email}
													onChange={(e) => this.setState({ email: e.target.value })}
												/>
											</div>
											<div className="input-item input-item-phone ltn__custom-icon">
												<input
													type="text"
													name="phone"
													placeholder="Type your phone number...."
													value={this.state.phone}
													onChange={(e) => this.setState({ phone: e.target.value })}
												/>
											</div>
											<label className="mb-0">
												<input
													type="checkbox"
													name="agree"
													checked={this.state.saveInfo}
													onChange={(e) => this.setState({ saveInfo: e.target.checked })}
												/>{' '}
												I consent to LBI4RENT contacting me by email or phone.
											</label>
											<div className="btn-wrapper">
												<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
													Submit
												</button>
											</div>
										</form>
									</div>
								</>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
							<div className="widget ltn__social-media-widget">
								<h4 className="ltn__widget-title ltn__widget-title-border-2">Information</h4>
								<div className="product-info">
									<div className="product-price"
										style={{
											fontSize: '18px',
											fontFamily: 'var(--ltn__heading-font)',
											fontWeight: 600,
											color: 'var(--ltn__secondary-color)',
										}}>
										<span>${new Intl.NumberFormat('en-US').format(property.price)}<label>/Week</label></span>
									</div>
									<ul className="ltn__list-item-2 ltn__list-item-2-before">
										<li><span>{property.bedrooms} <i className="flaticon-bed" /></span>
											Bedrooms
										</li>
										<li><span>{property.bathrooms} <i className="flaticon-clean" /></span>
											Bathrooms
										</li>
										<li><span>{property.propertySize} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
											Square Ft.
										</li>
									</ul>
								</div>
							</div>
							<h4 className="title-2">Calendar</h4>
							<div className="property-details-google-map mb-60">
								<div className='calendar-component'>
									<FullCalendar
										plugins={[dayGridPlugin]}
										initialView='dayGridMonth'
										events={this.state.calendarEvents}
										eventColor={this.state.calendarEvents.color}
									/>
								</div>
							</div>
							{/* Banner Widget */}
							<div className="widget ltn__banner-widget d-none go-top">
								<Link to="/shop"><img src={IMAGE_BASE_URL + "/LBI4RENT-Images/slider2.jpg"} alt="#" /></Link>
							</div>
						</aside>
					</div>
				</div>
			</div>
		</div>
	}
}

export default ShopDetails