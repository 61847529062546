import React, { Component } from 'react';
import { apiService } from '../../apiService';

class RemoveImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medias: this.props.medias.filter(media => media.type.startsWith('image/')),
      removedImages: new Set(),
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the medias prop has changed
    if (prevProps.medias !== this.props.medias) {
      this.setState({
        medias: this.props.medias.filter(media => media.type.startsWith('image/')),
        removedImages: new Set(), // Optionally reset removedImages if you want
      });
    }
  }

  toggleRemoveImage = (mediaId) => {
    this.setState(prevState => {
      const newRemovedImages = new Set(prevState.removedImages);
      if (newRemovedImages.has(mediaId)) {
        newRemovedImages.delete(mediaId);
      } else {
        newRemovedImages.add(mediaId);
      }
      return { removedImages: newRemovedImages };
    });
  };

  saveRemovedImages = async () => {
    const { removedImages } = this.state;

    const data = Array.from(removedImages);

    try {
      const response = this.props.propertyId === -1
        ? await apiService.delete('/Media/gallery-media', data)
        : await apiService.delete('/Media', data);

      alert('Images removed successfully!');

      this.setState(prevState => ({
        medias: prevState.medias.filter(media => !removedImages.has(media.id)),
        removedImages: new Set(),
      }));
    } catch (error) {
      alert('Error removing images. Please try again.');
    }
  };


  render() {
    const { medias, removedImages } = this.state;

    return (
      <div style={{ marginBottom: "10px" }} >
        <div className="images-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', margin: '20px 0' }}>
          {medias.map(media => (
            <div key={media.id} className="image-item" style={{ position: 'relative' }}>
              <img
                src={media.link}
                alt="Property"
                style={{
                  width: '100px', // Adjust the size as needed
                  height: '100px', // Adjust the size as needed
                  objectFit: 'cover',
                  cursor: 'pointer',
                  border: removedImages.has(media.id) ? '3px solid red' : 'none', // Red border if marked for removal
                }}
                onClick={() => this.toggleRemoveImage(media.id)}
              />
              {removedImages.has(media.id) && (
                <div style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  background: 'rgba(255, 0, 0, 0.5)', // Red overlay
                  color: 'white',
                  padding: '2px 5px',
                  fontSize: '12px',
                }}>
                  Marked for removal
                </div>
              )}
            </div>
          ))}
        </div>
        <button
          onClick={this.saveRemovedImages}
          className='btn theme-btn-1 btn-effect-1 text-uppercase'
        >
          Remove ({removedImages.size})
        </button>
      </div>
    );
  }
}

export default RemoveImages;
