import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

    render() {
		const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={IMAGE_BASE_URL+"/LBI4RENT-Images/10.png"} alt="Icon Image" />
						</div>
						<h3>Email Address</h3>
						<p>info@lbi4rent.com <br />
						&nbsp;</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={IMAGE_BASE_URL+"/LBI4RENT-Images/11.png"} alt="Icon Image" />
						</div>
						<h3>Phone Number</h3>
						<p>(609) 587-7671 <br /> (609) 462-8330</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={IMAGE_BASE_URL+"/LBI4RENT-Images/12.png"} alt="Icon Image" />
						</div>
						<h3>Address</h3>
						<p>Long Beach Island (LBI) <br />
						New Jersey, US</p>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo