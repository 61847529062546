import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { apiService } from '../../apiService';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            redirectToHome: false,
            validationError: '', // Added for displaying validation errors
        };
    }

    validateInput = () => {
        const { email, password } = this.state;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
        if (!email || !emailRegex.test(email)) {
            this.setState({ validationError: 'Please enter a valid email address.' });
            return false;
        } else if (!password || password.length < 8) { // Example: Minimum password length of 8 characters
            this.setState({ validationError: 'Password must be at least 8 characters long.' });
            return false;
        }
        this.setState({ validationError: '' }); // Clear any existing errors
        return true;
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (!this.validateInput()) {
            return; // Stop submission if validation fails
        }

        const { email, password } = this.state;

        try {
            let response = await apiService.login({ email, password });
            this.setState({ redirectToHome: true });
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    render() {
        if (this.state.redirectToHome) {
            window.location.href = "/";
        }

        return (
            <div>
                <div className="ltn__login-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title">Sign In</h1>
                                    <p>For Admin Use Only</p>
                                </div>
                            </div>
                        </div>
                        <div className="account-login-inner">
                            <form className="ltn__form-box contact-form-box" onSubmit={this.handleSubmit}>
                                <input type="text" name="email" placeholder="Email*" value={this.state.email} onChange={this.handleChange} />
                                <input type="password" name="password" placeholder="Password*" value={this.state.password} onChange={this.handleChange} />
                                <div className="btn-wrapper mt-0">
                                    <button className="theme-btn-1 btn btn-block" type="submit">SIGN IN</button>
                                </div>
                            </form>
                            {this.state.validationError && <div className="alert alert-danger">{this.state.validationError}</div>}
                            {this.state.errorMessage && <div className="alert alert-danger">{this.state.errorMessage}</div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
