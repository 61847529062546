export const addProperty = property => ({
    type: 'ADD_PROPERTY',
    payload: property,
});

export const removeProperty = propertyId => ({
    type: 'REMOVE_PROPERTY',
    payload: propertyId,
});

export const setProperties = properties => ({
    type: 'SET_PROPERTIES',
    payload: properties,
});