import React, { Component } from 'react';

class BannerV3 extends Component {
	render() {
		const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

		return <div className="ltn__slider-area ltn__slider-3  section-bg-2">
			<div className="ltn__slide-one-active slick-slide-arrow-1">
				{/* ltn__slide-item */}
				<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" data-bs-bg={IMAGE_BASE_URL + "/LBI4RENT-Images/slider1.jpg"}>
					<div className="ltn__slide-item-inner text-center">
						<div className="container">
							<div className="row"  style={{ width: "100%" }}>
								<div className="col-lg-12 align-self-center">
									<div className="slide-item-info">
										<div className="slide-item-info-inner ltn__slide-animation">
											<div className="slide-video mb-50 d-none">
												<a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
													<i className="fa fa-play" />
												</a>
											</div>
											<br />
											<br />
											<h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> LBI4RENT</h6>
											<h1 className="slide-title animated ">Long Beach Island <br /> Vacation Rentals</h1>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	}
}

export default BannerV3