import React, { Component } from 'react';
import { apiService } from '../../apiService';

class Reviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.approveReview = this.approveReview.bind(this);
        this.rejectReview = this.rejectReview.bind(this);
    }

    async approveReview(reviewId) {
        const data = { isAllowed: true, reviewId };
        try {
            const response = await apiService.put("/Review", data);
            alert('Review approved successfully!');
            // You might want to refresh the reviews or update the state to reflect the change
        } catch (error) {
            alert('Error approving review. Please try again.');
        }
    }

    async rejectReview(reviewId) {
        const data = { isAllowed: false, reviewId };
        try {
            const response = await apiService.put("/Review", data);
            alert('Review rejected successfully!');
            // You might want to refresh the reviews or update the state to reflect the change
        } catch (error) {
            alert('Error rejecting review. Please try again.');
        }
    }

    render() {
        const reviews = this.props.reviews.filter(r => r.isAllowed === null);

        return (
            <div className="ltn__comment-area mb-30">
                <div className="ltn__comment-inner">
                    <ul>
                        {reviews?.map((review, index) => (
                            <li key={index}>
                                <hr />
                                <div className="ltn__comment-item clearfix">
                                    <div className="ltn__commenter-comment">
                                        <h6>{review.name}</h6>
                                        <div className="product-ratting">
                                            <ul>
                                                {Array.from({ length: review.rating }, (_, index) => (
                                                    <li key={index}><a href="#"><i className="fas fa-star" /></a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <p>{review.comment}</p>
                                        <span className="ltn__comment-reply-btn">
                                            {new Date(review.dateCreated).toLocaleDateString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric'
                                            })}
                                        </span>
                                        <button 
                                            className='btn theme-btn-1 btn-effect-1 text-uppercase' 
                                            onClick={() => this.approveReview(review.id)}
                                        >
                                            Approve
                                        </button>
                                        <button 
                                            className='btn theme-btn-1 btn-effect-1 text-uppercase' 
                                            onClick={() => this.rejectReview(review.id)}
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Reviews;
