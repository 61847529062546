import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CallToActonV1 extends Component {

	render() {

		return <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" >
			<div className="container"  style={{ display: "flex", justifyContent: "center", width: "100%" }}>
				<div className="row"  style={{ width: "100%" }}>
					<div className="col-lg-12">
						<div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
							<div className="coll-to-info text-color-white">
								<h1>Looking for your dream rental?</h1>
								<p>We can help you realize your dream</p>
							</div>
							<div className="btn-wrapper go-top">
								<Link className="btn btn-effect-3 btn-white" to="/properties">Explore Rentals <i className="icon-next" /></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default CallToActonV1