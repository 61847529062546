const initialState = {
    properties: [],
};

const propertyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_PROPERTY':
            return {
                ...state,
                properties: [...state.properties, action.payload],
            };
        case 'REMOVE_PROPERTY':
            return {
                ...state,
                properties: state.properties.filter(property => property.id !== action.payload),
            };
        case 'SET_PROPERTIES':
            return {
                ...state,
                properties: action.payload,
            };
        default:
            return state;
    }
};

export default propertyReducer;