import React, { Component } from 'react';
import { apiService } from '../../apiService';

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: null,
    };
  }

  handleFileSelect = event => {
    this.setState({ selectedFiles: event.target.files });
  }

  handleSubmit = async event => {
    event.preventDefault();

    const { selectedFiles } = this.state;
    const propertyId = this.props.propertyId;

    if (!selectedFiles) {
      alert('Please select files first!');
      return;
    }

    const formData = new FormData();

    if (propertyId !== -1) {
      formData.append('PropertyId', propertyId); // Make sure the field name matches the DTO
    }
    Array.from(selectedFiles).forEach((file, index) => {
      formData.append('Files', file); // The field name should be 'Files', not `files[${index}]`
    });

    if (propertyId !== -1) {
      formData.append('IsMain', false);
    }

    try {
      const response = propertyId === -1
        ? await apiService.post('/Media/gallery-media', formData)
        : await apiService.post('/Media', formData);

      if (this.props.onUploadSuccess) {
        this.props.onUploadSuccess();
        alert('Images uploaded successfully! Please refresh the page.');
      }
    } catch (error) {
      alert('Error during file upload. Please try again.');
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <ul style={{ textDecoration: "none", listStyleType: "none", padding: "0" }}>
            <li>
              <input
                type="file"
                multiple
                onChange={this.handleFileSelect}
              />
            </li>
            <li>
              <button className='btn theme-btn-1 btn-effect-1 text-uppercase' type="submit">Upload</button>
            </li>
          </ul>
        </form>
      </div>
    );
  }
}

export default ImageUploader;
