import React, { Component } from 'react';

class VideoV1 extends Component {

	render() {
		const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__video-popup-area ltn__video-popup-margin---">
			<div className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image ltn__animation-pulse1" data-bs-bg={IMAGE_BASE_URL + "/LBI4RENT-IMAGES/Q8xFOkWLxw.png"}>
				<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href={IMAGE_BASE_URL + "/LBI4RENT-IMAGES/loveladies_video.mp4"} data-rel="lightcase:myCollection">
					<i className="fa fa-play" />
				</a>
			</div>
		</div>
	}
}

export default VideoV1