import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { apiService } from '../../apiService';


class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			endDate: moment().add(7, 'days'),
			focusedInput: null,
			name: '',
			email: '',
			phone: '',
			message: '',
			adults: '',
			children: '',
			formMessages: '',
			formStatus: '',
			errors: {},
		};
	}

	validateForm = () => {
		const errors = {};
		const { name, email, phone, message, adults, children } = this.state;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	
		if (!name.trim()) errors.name = "Name is required";
		if (!email.trim() || !emailRegex.test(email)) errors.email = "Invalid email address";
		if (!phone.trim()) errors.phone = "Phone number is required";
		if (!message.trim()) errors.message = "Message is required";
		if (isNaN(adults) || adults < 1) errors.adults = "Please select a valid number of adults";
		if (isNaN(children)) errors.children = "Please select a valid number of children";
	
		this.setState({ errors });
		return Object.keys(errors).length === 0;
	  }

	onDatesChange = ({ startDate, endDate }) => {
		this.setState({ startDate, endDate });
	}

	onFocusChange = (focusedInput) => {
		this.setState({ focusedInput });
	}

	handleInputChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		if (!this.validateForm()) {
			return;
		}
		const formData = {
			name: this.state.name,
			email: this.state.email,
			phone: this.state.phone,
			message: this.state.message,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			adults: isNaN(parseInt(this.state.adults)) ? 1 : parseInt(this.state.adults),
			children: isNaN(parseInt(this.state.children)) ? 0 : parseInt(this.state.children),
		};

		await apiService.post('/ContactForm', formData)
			.then((response) => {
				this.setState({
					formMessages: "Contact form submitted! We'll get back to you soon.",
					formStatus: 'success',
					name: '',
					email: '',
					phone: '',
					message: '',
					adults: '',
					children: '',
				});
			})
			.catch((error) => {
				this.setState({
					formMessages: error.message || 'Oops! An error occurred and your message could not be sent.',
					formStatus: 'error',
				});
			});
	}

	render() {
		const { errors } = this.state;
		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__contact-message-area mb-120 mb--100">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__form-box contact-form-box box-shadow white-bg">
							<h4 className="title-2">Contact Form</h4>
							<form id="contact-form" onSubmit={this.handleSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="input-item input-item-name ltn__custom-icon">
											<input value={this.state.name} onChange={this.handleInputChange} type="text" name="name" placeholder="Enter your name" />
											{errors.name && <p className="error">{errors.name}</p>}
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-item input-item-email ltn__custom-icon">
											<input value={this.state.email} onChange={this.handleInputChange} type="email" name="email" placeholder="Enter email address" />
											{errors.email && <p className="error">{errors.email}</p>}
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-item input-item-phone ltn__custom-icon">
											<input value={this.state.phone} onChange={this.handleInputChange} type="text" name="phone" placeholder="Enter phone number" />
											{errors.phone && <p className="error">{errors.phone}</p>}
										</div>
									</div>
									<div className="col-md-6">
										<div style={{ marginBottom: '30px' }} >
											<DateRangePicker
												startDate={this.state.startDate} // momentPropTypes.momentObj or null,
												startDateId="start_date_id" // PropTypes.string.isRequired,
												endDate={this.state.endDate} // momentPropTypes.momentObj or null,
												endDateId="end_date_id" // PropTypes.string.isRequired,
												onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
												focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
												onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
												numberOfMonths={2}
												isOutsideRange={() => false}
												showClearDates={false}
												small={false}
												block={true}
												regular={false}
												noBorder={true}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-item" >
											<select value={this.state.adults} onChange={this.handleInputChange} className="nice-select" name="adults">
												<option value="">Number of Adults</option>
												<option value="1">1 </option>
												<option value="2">2 </option>
												<option value="3">3 </option>
												<option value="4">4 </option>
												<option value="5">5 </option>
												<option value="6">6 </option>
												<option value="7">7 </option>
												<option value="8">8 </option>
												<option value="9">9 </option>
												<option value="10+">10+ </option>
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-item" >
											<select value={this.state.children} onChange={this.handleInputChange} className="nice-select" name="children">
												<option value="">Number of Children</option>
												<option value="0">0 </option>
												<option value="1">1 </option>
												<option value="2">2 </option>
												<option value="3">3 </option>
												<option value="4">4 </option>
												<option value="5">5 </option>
												<option value="6">6 </option>
												<option value="7">7 </option>
												<option value="8">8 </option>
												<option value="9">9 </option>
												<option value="10+">10+ </option>
											</select>
										</div>
									</div>
								</div>
								<div className="input-item input-item-textarea ltn__custom-icon">
									<textarea value={this.state.message} onChange={this.handleInputChange} name="message" placeholder="Enter message" />
									{errors.message && <p className="error">{errors.message}</p>}
								</div>
								<div className="btn-wrapper mt-0">
									<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Send</button>
								</div>
								<p className={`form-message mb-0 mt-20 ${this.state.formStatus}`} style={this.state.formStatus == "success" ? {color: "green"} : {color: "red"}}>
									{this.state.formMessages}
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default ContactForm