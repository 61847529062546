import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Team from './section-components/team-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const About_v1 = () => {
    const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

    return <div>
        <Helmet>
            <title>About LBI4RENT - Discover Our Story and Mission | Long Beach Island, NJ Beach House Rentals</title>
            <meta name="description" content="Learn about LBI4RENT's journey to becoming the top choice for beach house rentals in Long Beach Island, NJ. Our commitment to quality, customer satisfaction, and the local community sets us apart. Get to know our story and what makes us unique." />
            <meta name="keywords" content="about LBI4RENT, beach house rental mission, LBI rental history, luxury beach houses NJ, LBI community involvement" />
            <link rel="canonical" href="https://www.lbinj4rent.com/#/about" />
            {/* Open Graph tags for social media */}
            <meta property="og:title" content="About LBI4RENT - Our Story and Mission" />
            <meta property="og:description" content="Dive into the story of LBI4RENT and discover our passion for delivering exceptional beach house rental experiences in Long Beach Island, NJ. Learn about our values, community involvement, and what drives us." />
            <meta property="og:url" content="https://www.lbinj4rent.com/#/about" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={IMAGE_BASE_URL + "/LBI4RENT-Images/1/maing.jpg"} /> {/* Replace URL_TO_YOUR_ABOUT_US_IMAGE with the actual URL of an image that tells your story or represents your team/company culture */}
            <meta property="og:site_name" content="LBI4RENT" />
            <meta property="og:see_also" content="https://www.facebook.com/Lbi4rent" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle="About Us" />
        <AboutV4 />
        <Team />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_v1

