import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const ContactV1 = () => {
    var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

    return <div>
        <Helmet>
            <title>Contact Us - LBI4RENT | Reach Out for Beach House Rentals in Long Beach Island, NJ</title>
            <meta name="description" content="Ready to book your dream beach house rental or need more information? Contact LBI4RENT today for all inquiries about our Long Beach Island, NJ properties. We're here to help you plan the perfect LBI vacation." />
            <meta name="keywords" content="contact LBI4RENT, LBI rentals contact, beach house inquiries, Long Beach Island rentals, book beach house NJ" />
            <link rel="canonical" href="https://www.lbinj4rent.com/#/contact" />
            {/* Open Graph tags for social media */}
            <meta property="og:title" content="Contact LBI4RENT | Long Beach Island, NJ Beach House Rentals" />
            <meta property="og:description" content="Get in touch with LBI4RENT for any questions or to book your Long Beach Island beach house rental. Our team is dedicated to ensuring your LBI vacation is unforgettable." />
            <meta property="og:url" content="https://www.lbinj4rent.com/#/contact" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={IMAGE_BASE_URL + "/LBI4RENT-Images/1/maing.jpg"} /> {/* Replace URL_TO_YOUR_CONTACT_PAGE_IMAGE with the actual URL of an image that represents customer service or contact */}
            <meta property="og:site_name" content="LBI4RENT" />
            <meta property="og:see_also" content="https://www.facebook.com/Lbi4rent" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        <ContactInfo />
        <ContactForm />
        <Map />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ContactV1

