import React, { Component } from 'react';
import { apiService } from '../../apiService';

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.property.id,
            name: props.property.name || '',
            description: props.property.description || '',
            price: props.property.price || 0,
            bedrooms: props.property.bedrooms || '',
            bathrooms: props.property.bathrooms || '',
            sleeps: props.property.sleeps || '',
            petFee: props.property.petFee || 0,
            bedSizes: props.property.bedSizes || '',
            propertySize: props.property.propertySize || '',
        };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
    
        const patchData = [];
        Object.entries(this.state).forEach(([key, value]) => {
            if (value !== this.props.property[key]) {
                const patchOperation = {
                    op: "replace",
                    path: `/${key}`,
                    value: value
                };
                patchData.push(patchOperation);
            }
        });
    
        try {
            const response = await apiService.patch(`/Property/${this.state.id}`, patchData, {
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            });
            alert('Property details updated successfully!');
        } catch (error) {
            alert('Error during property update. Please try again.');
        }
    }    

    render() {
        return (
            <div className="ltn__comment-area mb-30">
                <div className="ltn__comment-inner">
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <label style={{ width: "100%" }}>
                                Name:
                                <input 
                                    type="text" 
                                    name="name" 
                                    value={this.state.name} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Description:
                                <textarea 
                                    name="description" 
                                    value={this.state.description} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Price (no commas or dollar signs):
                                <input 
                                    type="text" 
                                    name="price" 
                                    value={this.state.price} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Bedrooms:
                                <input 
                                    type="text" 
                                    name="bedrooms" 
                                    value={this.state.bedrooms} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Bathrooms:
                                <input 
                                    type="text" 
                                    name="bathrooms" 
                                    value={this.state.bathrooms} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Sleeps:
                                <input 
                                    type="text" 
                                    name="sleeps" 
                                    value={this.state.sleeps} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Pet Fee (no commas or dollar signs):
                                <input 
                                    type="text" 
                                    name="petFee" 
                                    value={this.state.petFee} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Bed Sizes:
                                <input 
                                    type="text" 
                                    name="bedSizes" 
                                    value={this.state.bedSizes} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <label style={{ width: "100%" }}>
                                Property Size (in square feet):
                                <input 
                                    type="text" 
                                    name="propertySize" 
                                    value={this.state.propertySize} 
                                    onChange={this.handleChange} 
                                />
                            </label>
                        </div>
                        <div>
                            <button className='btn theme-btn-1 btn-effect-1 text-uppercase' type="submit">Update Details</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Details;
