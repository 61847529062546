import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { apiService } from './apiService';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null as the initial state
    const [loading, setLoading] = useState(true); // Initialize loading to true

    useEffect(() => {
        const verifyAuthentication = async () => {
            try {
                const authStatus = await apiService.verifyAuth(); // Assuming this returns true/false
                setIsAuthenticated(authStatus.isAuthenticated);
            } catch (error) {
                setIsAuthenticated(false); // Consider authentication failed on error
            } finally {
                setLoading(false); // Always set loading to false after the operation
            }
        };

        verifyAuthentication();
    }, []);

    // Handle the loading state separately
    if (loading) {
        return <div>Loading...</div>;
    }

    // Render the route or redirect based on the authentication status
    return (
        <Route {...rest} render={props => (
            isAuthenticated === true ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        )} />
    );
};

export default ProtectedRoute;
