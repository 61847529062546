import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiService } from '../../apiService';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class FeaturedItemV1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: null,
		};
	}

	render() {
		const { loading, error } = this.state;
		let { properties } = this.props;
		let publicUrl = process.env.PUBLIC_URL + '/';
		var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

		properties = [...properties].sort((a, b) => a.id - b.id);

		function SamplePrevArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "block", left: "25px" }}
					onClick={onClick}
				>
					<FontAwesomeIcon icon={faArrowLeft} />
				</div>
			);
		}

		function SampleNextArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "block", right: "25px" }}
					onClick={onClick}
				>
					<FontAwesomeIcon icon={faArrowRight} />
				</div>
			);
		}

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			autoplay: true, // Enable auto-scroll
            autoplaySpeed: 3000, // Speed of auto-scroll
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: true,
						initialSlide: 0 // Ensure the slider starts with the first slide
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						initialSlide: 0 // Ensure the slider starts with the first slide for large mobile devices
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						dots: false,
						initialSlide: 0 // Ensure the slider starts with the first slide for smaller mobile devices
					}
				}
			]
		};

		if (loading) {
			return <div>Loading...</div>;
		}

		if (error) {
			return <div>Error: {error}</div>;
		}

		return <div>
			<div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area ltn__section-title-2--- text-center">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Properties</h6>
								<h1 className="section-title">Featured Listings</h1>
							</div>
						</div>
					</div>
					{properties?.length > 0 && (
						<Slider style={{ paddingLeft: 0, paddingRight: 0 }} {...settings}>
							{properties?.map((property, index) => (
								<div key={index} className="col-lg-12">
									<div className="ltn__product-item ltn__product-item-4 text-center---">
										<div className="product-img go-top">
											<Link to={"/property-details/" + property.id}><img className="product-image" src={IMAGE_BASE_URL + property.medias.find(media => media.order == 1)?.link} alt="#" /></Link>
											<div className="product-badge">
												<ul>
													<li className="sale-badge bg-green">{"For " + property.offerType}</li>
												</ul>
											</div>
											<div className="product-img-location-gallery">
												<div className="product-img-location go-top">
													<ul>
														<li>
															<i className="flaticon-pin" /> {property.city + ", LBI"}
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-info">
											<div className="product-price">
												<span>${new Intl.NumberFormat('en-US').format(property.price)}<label>/Week</label></span>
											</div>
											<h2 className="product-title go-top"><Link to={"/property-details/" + property.id}>{property.name}</Link></h2>
											<div className="product-description">
												<p style={{
													display: "-webkit-box",
													WebkitLineClamp: "2",
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxHeight: "200%",
													lineHeight: "100%",
													whiteSpace: "normal"
												}}>{property.description}</p>
											</div>
											<ul className="ltn__list-item-2 ltn__list-item-2-before">
												<li><span>{property.bedrooms} <i className="flaticon-bed" /></span>
													Bedrooms
												</li>
												<li><span>{property.bathrooms} <i className="flaticon-clean" /></span>
													Bathrooms
												</li>
												<li><span>{property.propertySize} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
													Square Ft.
												</li>
											</ul>
										</div>
									</div>
								</div>
							))}
							{/*  */}
						</Slider>
					)}
				</div>
			</div>
		</div>
	}
}

const mapStateToProps = state => {
	return {
		properties: state.property.properties,
	};
};

export default connect(mapStateToProps)(FeaturedItemV1);