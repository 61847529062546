import React, { useEffect } from 'react';
import { useSelector, connect } from 'react-redux'; // Import connect from react-redux
import Navbar from './global-components/navbar-v3';
import Banner from './section-components/banner-v3';
import FeaturedItem from './section-components/featured-item-v1';
import Video from './section-components/video-v1';
import Testimonial from './section-components/testimonial-v3';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const Home_V3 = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated); // Use useSelector to get isAuthenticated
    const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

    useEffect(() => {
    }, [isAuthenticated]);

    return (
        <div>
            <Helmet>
                <title>LBI4RENT: Long Beach Island, NJ's Premier Destination for Vacation Rentals</title>
                <meta name="description" content="Explore top-tier beach house rentals with LBI4RENT at Long Beach Island, NJ. Experience luxury living with stunning ocean views, prime locations, and exclusive amenities. Perfect for families and groups. Book your LBI getaway today!" />
                <meta name="keywords" content="LBI4RENT, Long Beach Island rentals, beach house rentals NJ, luxury vacation homes LBI, oceanfront properties LBI, LBI, Long Beach Island, LBI rentals" />
                <link rel="canonical" href="https://www.lbinj4rent.com/#/" />
                {/* Add additional meta tags for social media as needed */}
                <meta property="og:title" content="LBI4RENT: Long Beach Island, NJ's Premier Destination for Vacation Rentals" />
                <meta property="og:description" content="Discover your ideal beach house rental in Long Beach Island, NJ with LBI4RENT. Enjoy luxury amenities, beautiful ocean views, and the best of LBI. Book now for an unforgettable vacation experience." />
                <meta property="og:url" content="https://www.lbinj4rent.com/#/" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={IMAGE_BASE_URL + "/LBI4RENT-Images/1/maing.jpg"} /> {/* Replace URL_TO_YOUR_IMAGE with the link to an image showcasing one of your properties or a promotional graphic */}
                <meta property="og:see_also" content="https://www.facebook.com/Lbi4rent" />
            </Helmet>
            <Navbar />
            <Banner />
            <FeaturedItem />
            <Video />
            <br />
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Home_V3);
