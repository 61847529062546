import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavbarV3 from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import ImageUploader from './manage-components/image-upload';
import RemoveImages from './manage-components/remove-images';
import Reviews from './manage-components/reviews';
import Details from './manage-components/details';
import { Helmet } from 'react-helmet';
import { apiService } from '../apiService';
import { setProperties } from '../actions/propertyAction';
import ReorderImages from './manage-components/reorder-images';

class Manage extends Component {
    constructor(props) {
        super(props);
this.state = {
    expandedPropertyId: null,
    topic: null,
    lastUpdatedPropertyId: null,
    gallery: [],
    refreshKey: 0, // Add this line
};
    }

    componentDidMount() {
        this.getGalleryMedia();
    }

    toggleExpand = (propertyId, newTopic) => {
        this.setState(prevState => {
            // Check if clicking on a different topic for the same property
            if (prevState.expandedPropertyId === propertyId && prevState.topic !== newTopic) {
                return { expandedPropertyId: propertyId, topic: newTopic };
            }
            // Toggle behavior for the same topic or different properties
            return {
                expandedPropertyId: prevState.expandedPropertyId === propertyId ? null : propertyId,
                topic: prevState.expandedPropertyId === propertyId ? null : newTopic,
            };
        });
    }

    updateImages = async (propertyId) => {
        // Optionally, fetch updated property details here if necessary
        // For a simple refresh, just toggling a state to cause a re-render is enough
        this.setState({ lastUpdatedPropertyId: propertyId });

        try {
            const data = await apiService.get('/Property');
            this.props.setProperties(data);
        } catch (error) {
        }
    }

    getGalleryMedia = async () => {
        try {
            const response = await apiService.get('/Media/gallery-media');
            this.setState({ gallery: response });
        } catch (error) {
        }
    }

    saveImageOrder = async (propertyId, newOrder) => {
        const orderUpdates = newOrder.map((item, index) => ({
            MediaId: item.id,
            NewOrder: index + 1
        }));
        try {
            await apiService.post('/Media/change-order', orderUpdates);
            this.updateImages(propertyId);
            this.setState(prevState => ({
                refreshKey: prevState.refreshKey + 1 // Increment the key to force refresh
            }));
            alert('Images order changed successfully! Please refresh the page.');
        } catch (error) {
            alert('Error removing images. Please try again.');
        }
    };    

    render() {
        const { properties } = this.props;
        const { expandedPropertyId, topic, gallery } = this.state;
        const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

        return (
            <div>
                <Helmet>
                    <title>Manage - LBI4RENT</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <NavbarV3 />
                <PageHeader headertitle="Manage" customclass="mb-0" />
                <div className="tab-pane" id="liton_product_list">
                    <div className="ltn__product-tab-content-inner ltn__product-list-view"></div>
                    <div className="property-boxes" style={{ padding: "20px", marginTop: "50px", display: "flex", flexWrap: "wrap" }}>
                        {properties.map((property, index) => (
                            <div key={index} className="col-lg-6">
                                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                                    <div className="product-info">
                                        <h2 className="product-title go-top"><Link to={`/property-details/` + property.id}>{property.name}</Link></h2>
                                        <div className="product-img-location go-top">
                                            <ul>
                                                <li>
                                                    <Link to={`/property-details/` + property.id}><i className="flaticon-pin" /> {property.city}, LBI</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                            <li>
                                                <button onClick={() => this.toggleExpand(property.id, "reviews")}>
                                                    <span>{property.reviews.filter(r => r.isAllowed === null).length} </span>
                                                    Pending Review(s)
                                                </button>
                                            </li>
                                            <li>
                                                <button onClick={() => this.toggleExpand(property.id, "details")}><Link>Edit Details</Link></button>
                                            </li>
                                            <li><button onClick={() => this.toggleExpand(property.id, "images")}><Link>Manage Images</Link></button>
                                            </li>
                                        </ul>
                                        {expandedPropertyId === property.id && topic === "images" &&
                                            <>
                                                <h3>Remove Images</h3>
                                                <RemoveImages key={this.state.lastUpdatedPropertyId} propertyId={property.id} medias={property.medias.sort((a, b) => a.order - b.order)} />
                                                <hr />
                                                <h3>Upload Images</h3>
                                                <ImageUploader propertyId={property.id} onUploadSuccess={() => this.updateImages(property.id)} />
                                                <hr />
                                                <h3>Reorder Images</h3>
                                                <ReorderImages key={`reorder-${this.state.refreshKey}`} medias={property.medias.sort((a, b) => a.order - b.order)} onSave={(newOrder) => this.saveImageOrder(property.id, newOrder)} />
                                            </>
                                        }
                                        {expandedPropertyId === property.id && topic === "reviews" &&
                                            <>
                                                {property.reviews.filter(r => r.isAllowed === null).length === 0 ? (
                                                    <p>No pending reviews for {property.name}.</p>
                                                )
                                                    :
                                                    (
                                                        <>
                                                            <h3>Pending Reviews for {property.name}</h3>
                                                            <p>If rejected, nobody will know. If accepted, it will show up as a review on that property's page.</p>
                                                            <Reviews reviews={property.reviews} />
                                                        </>
                                                    )}
                                            </>
                                        }
                                        {expandedPropertyId === property.id && topic === "details" &&
                                            <Details property={property} />
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div key={-1} className="col-lg-6">
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                                <div className="product-info">
                                    <h2 className="product-title go-top"><Link to={`/gallery`}>The Island Gallery</Link></h2>
                                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                        <li><button onClick={() => this.toggleExpand(-1, "images")}><Link>Manage Images</Link></button>
                                        </li>
                                    </ul>
                                    {expandedPropertyId === -1 && topic === "images" &&
                                        <>
                                            <h3>Remove Images</h3>
                                            <RemoveImages key={this.state.lastUpdatedPropertyId} propertyId={-1} medias={gallery} />
                                            <hr />
                                            <h3>Upload Images</h3>
                                            <ImageUploader propertyId={-1} onUploadSuccess={() => this.updateImages(-1)} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    properties: state.property.properties,
});

const mapDispatchToProps = dispatch => ({
    setProperties: (properties) => dispatch(setProperties(properties))
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
