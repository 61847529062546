import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from './shop-sidebar';

class ShopGridV1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			searchQuery: "",
			sortKey: "default",
			filters: {
				beds: [],
				baths: [],
				location: [],
				propertyType: []
			}
		};		
	}

	handleSortChange = (e) => {
		this.setState({ sortKey: e.target.value });
	};

	handleSearchChange = (e) => {
		this.setState({ searchQuery: e.target.value.toLowerCase() });
	};

	handleFilterChange = (filterCategory, value) => {
		this.setState(prevState => {
			const filters = { ...prevState.filters };
			if (filters[filterCategory].includes(value)) {
				filters[filterCategory] = filters[filterCategory].filter(item => item !== value);
			} else {
				filters[filterCategory].push(value);
			}
			return { filters };
		}, () => {
		});
	};	

	render() {
		const { error } = this.state;
		var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		const properties = this.props.properties;

		if (error) {
			return <div>Error: {error}</div>;
		}

		let filteredProperties = properties;

		if (this.state.searchQuery) {
			filteredProperties = filteredProperties.filter(property =>
				property.name.toLowerCase().includes(this.state.searchQuery) ||
				property.city.toLowerCase().includes(this.state.searchQuery)
				// Add other fields if you want to include them in the search
			);
		}

		switch (this.state.sortKey) {
			case "priceLowToHigh":
				filteredProperties.sort((a, b) => a.price - b.price);
				break;
			case "priceHighToLow":
				filteredProperties.sort((a, b) => b.price - a.price);
				break;
			// Add other sorting criteria if needed
			default:
				// Default sorting logic (if any), or leave this block empty
				break;
		}

		Object.keys(this.state.filters).forEach(filterKey => {
			const filterValues = this.state.filters[filterKey];
			if (filterValues.length > 0) {
				filteredProperties = filteredProperties.filter(property => {
					if (filterKey === 'location') {
						return filterValues.includes(property.city);
					}
		
					if (filterKey === 'beds') {
						const propertyValue = property.bedrooms;
						if (propertyValue.includes('/')) {
							const rangeParts = propertyValue.split('/').map(Number);
							const rangeStart = rangeParts[0];
							const rangeEnd = rangeParts[1];
		
							return filterValues.some(filterValue => {
								if (filterValue === "5+") {
									return rangeStart >= 5 || rangeEnd >= 5;
								}
								const numFilterValue = Number(filterValue);
								return numFilterValue >= rangeStart && numFilterValue <= rangeEnd;
							});
						} else {
							if (filterValues.includes("5+")) {
								return Number(propertyValue) >= 5;
							}
							return filterValues.includes(propertyValue);
						}
					}
		
					if (filterKey === 'baths') {
						// Handling decimal values for bathrooms, rounding down for comparison
						const propertyValue = Math.floor(Number(property.bathrooms));
						if (filterValues.includes("3+")) {
							return propertyValue >= 3;
						}
						return filterValues.includes(propertyValue.toString());
					}
				});
			}
		});		

		return <div>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						<div className="col-lg-8  mb-100">
							<div className="ltn__shop-options">
								<ul className="justify-content-start">
									<li>
										<div className="ltn__grid-list-tab-menu ">
											<div className="nav">
												<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
												<a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list" /></a>
											</div>
										</div>
									</li>
									<li className="d-none">
										<div className="showing-product-number text-right">
											<span>Showing 1–{properties.length} of {properties.length} results</span>
										</div>
									</li>
									<li>
										<div className="short-by text-center">
											<select className="nice-select" value={this.state.sortKey} onChange={this.handleSortChange}>
												<option value="default">Default Sorting</option>
												<option value="priceLowToHigh">Sort by price: low to high</option>
												<option value="priceHighToLow">Sort by price: high to low</option>
											</select>
										</div>
									</li>
								</ul>
							</div>
							<div className="tab-content">
								<div className="tab-pane fade active show" id="liton_product_grid">
									<div className="ltn__product-tab-content-inner ltn__product-grid-view">
										<div className="row">
											<div className="col-lg-12">
												{/* Search Widget */}
												<div className="ltn__search-widget mb-30">
													<form action="#" onSubmit={(e) => e.preventDefault()}>
														<input 
															type="text" 
															name="search_uniqueName123" 
															placeholder="Search your keyword..." 
															value={this.state.searchQuery} 
															onChange={this.handleSearchChange} 
															autoComplete="off" 
														/>
														<button type="submit"><i className="fas fa-search" /></button>
													</form>
												</div>
											</div>
											{/* ltn__product-item */}
											{filteredProperties.map((property, index) => (
												<div key={index} className="col-xl-6 col-sm-6 col-12">
													<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
														<div className="product-img go-top">
															<Link to={"/property-details/" + property.id}><img src={IMAGE_BASE_URL + property.medias.find(media => media.order == 1).link} alt="#" /></Link>
														</div>
														<div className="product-info">
															<div className="product-badge">
																<ul>
																	<li className="sale-badg">For {property.offerType}</li>
																</ul>
															</div>
															<h2 className="product-title go-top"><Link to={"/property-details/" + property.id}>{property.name}</Link></h2>
															<div className="product-img-location go-top">
																<ul>
																	<li>
																		<Link to="/contact"><i className="flaticon-pin" /> {property.city}, LBI</Link>
																	</li>
																</ul>
															</div>
															<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
																<li><span>{property.bedrooms} </span>
																	Bed
																</li>
																<li><span>{property.bathrooms} </span>
																	Bath
																</li>
																<li><span>{property.propertySize} </span>
																	Square Ft
																</li>
																<li><span>{property.propertyType} </span>
																</li>
															</ul>
														</div>
														<div className="product-info-bottom">
															<div className="product-price">
																<span>${new Intl.NumberFormat('en-US').format(property.price)}<label>/Week</label></span>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="liton_product_list">
									<div className="ltn__product-tab-content-inner ltn__product-list-view">
										<div className="row">
											<div className="col-lg-12">
												{/* Search Widget */}
												<div className="ltn__search-widget mb-30">
													<form action="#">
														<input type="text" name="search" placeholder="Search your keyword..." />
														<button type="submit"><i className="fas fa-search" /></button>
													</form>
												</div>
											</div>
											{/* ltn__product-item */}
											{filteredProperties.map((property, index) => (
												<div key={index} className="col-lg-12">
													<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
														<div className="product-img go-top">
															<Link to={"/property-details/" + property.id}><img src={IMAGE_BASE_URL + property.medias.find(media => media.order == 1).link} alt="#" /></Link>
														</div>
														<div className="product-info">
															<div className="product-badge-price">
																<div className="product-badge">
																	<ul>
																		<li className="sale-badg">For {property.offerType}</li>
																	</ul>
																</div>
																<div className="product-price">
																	<span>${new Intl.NumberFormat('en-US').format(property.price)}<label>/Week</label></span>
																</div>
															</div>
															<h2 className="product-title go-top"><Link to={"/property-details/" + property.id}>{property.name}</Link></h2>
															<div className="product-img-location go-top">
																<ul>
																	<li>
																		<Link to="/contact"><i className="flaticon-pin" /> {property.city}, LBI</Link>
																	</li>
																</ul>
															</div>
															<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
																<li><span>{property.bedrooms} </span>
																	Bed
																</li>
																<li><span>{property.bathrooms} </span>
																	Bath
																</li>
																<li><span>{property.propertySize} </span>
																	Square Ft
																</li>
																<li><span>{property.propertyType} </span>
																</li>
															</ul>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
						<Sidebar propertyCount={filteredProperties.length} onFilterChange={this.handleFilterChange} />
					</div>
				</div>
			</div>
		</div>

	}
}

const mapStateToProps = state => {
    return {
        properties: state.property.properties,
    };
};

export default connect(mapStateToProps)(ShopGridV1);