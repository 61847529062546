import { createStore, combineReducers } from 'redux';
import authReducer from './actions/authReducer';
import propertyReducer from './actions/propertyReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    property: propertyReducer,
});

const store = createStore(rootReducer);

export default store;
