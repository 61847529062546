import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import Login from './section-components/login';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const LoginV1 = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    return <div>
        <Helmet>
            <title>Login - LBI4RENT</title>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle="Account" subheader="Login" />
        <Login />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default LoginV1

