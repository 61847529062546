import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PortfolioV1 extends Component {

    render() {
		const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		const media = this.props.media;
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__gallery-area mb-120">
				<div className="container mobile-style-container-top" style={{ alignItems: "center" }}>
					<div className="row mobile-container-style" style={{ width: "100%" }}>
						<div className="col-lg-12">
							<div className="section-title-area ltn__section-title-2--- text-center">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Gallery</h6>
								<h1 className="section-title">The Island</h1>
							</div>
						</div>
					</div>
					{/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
					<div className=" row ltn__gallery-style-2 ltn__gallery-info-hide---" style={{ height: "fit-content", width: "100%" }}>
						{media && media.map((item, index) => (
							<div key={index} className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
							<div className="ltn__gallery-item-inner">
								<div className="ltn__gallery-item-img">
								<a href={IMAGE_BASE_URL + item.link} data-rel="lightcase:myCollection">
									<img src={IMAGE_BASE_URL + item.link} className="gallery-image" alt={"gallery"} />
								</a>
								</div>
							</div>
							</div>
						))}               
					</div>
					{/* <div className="btn-wrapper text-center">
						<Link to="#" className="btn btn-transparent btn-effect-3 btn-border">LOAD MORE +</Link>
					</div> */}
					</div>
			</div>
        }
}

export default PortfolioV1