import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class ProductSliderV1 extends Component {
    isMobileDevice = () => window.innerWidth <= 1024;

    handleImageClick = (e, imageLink) => {
        e.preventDefault(); // Prevent default link action
        // Initialize and open Lightcase with the image link
        window.lightcase.start({
            href: imageLink,
            rel: 'lightcase:myCollection'
        });
    };

    render() {
        const { media } = this.props;
        const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

        // Settings for the slider
        const settings = {
            dots: false, // Show dot indicators
            infinite: true, // Infinite looping
            speed: 500, // Animation speed
            slidesToShow: 3, // Number of slides to show at a time
            slidesToScroll: 1, // Number of slides to scroll at a time
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true, // Enable swipe to slide on mobile
            touchMove: true, // Enable touch move
            initialSlide: this.isMobileDevice() ? 1 : 0,
            responsive: [
                {
                    breakpoint: 1024, // Tablet breakpoint
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 600, // Large mobile breakpoint
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true
                    }
                },
                {
                    breakpoint: 480, // Smaller mobile breakpoint
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true, // Optionally hide arrows on the smallest screens
                        dots: false, // Keeping dots enabled for navigation
                    }
                }
            ]
        };

        function SamplePrevArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                    className={className}
                    style={{ ...style, display: "block", left: "25px" }}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
            );
        }

        function SampleNextArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                    className={className}
                    style={{ ...style, display: "block", right: "25px" }}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
            );
        }

        return (
            <div className="ltn__img-slider-area mb-90">
                <div className="container-fluid">
                    <div className="row ltn__no-gutter-all">
                        {media?.length > 0 && (
                            <Slider style={{ paddingLeft: 0, paddingRight: 0 }} {...settings}>
                                {media
                                    .filter(image => image.type && image.type.startsWith('image/')) // Filter to include only images
                                    .sort((a, b) => a.order - b.order) // Sort by order property
                                    .map((image, index) => (
                                        <div key={index} className="col-lg-12">
                                            <div className="ltn__img-slide-item-4">
                                                <a href={IMAGE_BASE_URL + image.link} data-rel="lightcase:myCollection" onClick={(e) => this.handleImageClick(e, IMAGE_BASE_URL + image.link)}>
                                                    <img src={IMAGE_BASE_URL + image.link} alt="Image" className="slider-image" />
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductSliderV1;
