import React, { Component } from 'react';

class Sidebar extends Component {
	render() {
		const propertyLength = this.props?.propertyCount || 0;

		return (
			<div className="col-lg-4  mb-100">
				<aside className="sidebar ltn__shop-sidebar">
					<h3 className="mb-10">Advanced Information</h3>
					<label className="mb-30"><small>{propertyLength} results </small></label>
					{/* Advance Information widget */}
					<div className="widget ltn__menu-widget">
						<h4 className="ltn__widget-title">Beds</h4>
						<ul>
							<li>
								<label className="checkbox-item">1
									<input type="checkbox" onChange={() => this.props.onFilterChange('beds', '1')} />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">2
									<input type="checkbox" onChange={() => this.props.onFilterChange('beds', '2')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">3
									<input type="checkbox" onChange={() => this.props.onFilterChange('beds', '3')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">4
									<input type="checkbox" onChange={() => this.props.onFilterChange('beds', '4')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">5+
									<input type="checkbox" onChange={() => this.props.onFilterChange('beds', '5+')}  />
									<span className="checkmark" />
								</label>
							</li>
						</ul>
						<hr />
						<h4 className="ltn__widget-title">Baths</h4>
						<ul>
							<li>
								<label className="checkbox-item">1
									<input type="checkbox" onChange={() => this.props.onFilterChange('baths', '1')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">2
									<input type="checkbox" onChange={() => this.props.onFilterChange('baths', '2')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">3
									<input type="checkbox" onChange={() => this.props.onFilterChange('baths', '3')}  />
									<span className="checkmark" />
								</label>
							</li>
						</ul>
						<hr />
						<h4 className="ltn__widget-title">Location</h4>
						<ul>
							<li>
								<label className="checkbox-item">Loveladies
									<input type="checkbox" onChange={() => this.props.onFilterChange('location', 'Loveladies')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">Ship Bottom
									<input type="checkbox" onChange={() => this.props.onFilterChange('location', 'Ship Bottom')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">Surf City
									<input type="checkbox" onChange={() => this.props.onFilterChange('location', 'Surf City')}  />
									<span className="checkmark" />
								</label>
							</li>
							<li>
								<label className="checkbox-item">North Beach Haven
									<input type="checkbox" onChange={() => this.props.onFilterChange('location', 'North Beach Haven')}  />
									<span className="checkmark" />
								</label>
							</li>
						</ul>
					</div>
				</aside>
			</div>
		)
	}
}

export default Sidebar;
