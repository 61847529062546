const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchData = async (path, method = 'GET', data = null) => {
    const url = `${API_BASE_URL}${path}`;
    const headers = new Headers();

    if (!((path === '/Media' || path === '/Media/gallery-media') && method === 'POST')) {
        headers.append('Content-Type', 'application/json');
    }

    const config = {
        method,
        headers,
        credentials: 'include',
    };
    if (data !== null) {
        if ((path === '/Media' || path === '/Media/gallery-media') && method === 'POST') {
            config.body = data;
        } else {
            config.body = JSON.stringify(data);
        }
    }

    try {
        const response = await fetch(url, config);
        const json = await response?.json();

        if (!response?.ok) {
            let message = json?.message || 'Something went wrong';
            throw new Error(message);
        }

        return json;
    } catch (error) {
        throw error;
    }
};

export const apiService = {
    get: (path) => fetchData(path),
    post: (path, data) => fetchData(path, 'POST', data),
    put: (path, data) => fetchData(path, 'PUT', data),
    patch: (path, data) => fetchData(path, 'PATCH', data),
    delete: (path, data) => fetchData(path, 'DELETE', data),
    login: (data) => fetchData('/User/login', 'POST', data),
    logout: () => fetchData('/User/logout', 'POST'),
    verifyAuth: () => fetchData('/User/validate-token'),
};
