import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Social from '../section-components/social';
import { apiService } from '../../apiService';

class NavbarV3 extends Component {
	constructor(props) {
        super(props);
        this.state = {
            isMobileMenuVisible: false,
        };
    }

	toggleMobileMenu = (event) => {
        event.preventDefault(); // Prevents navigating to the anchor
        this.setState(prevState => ({
            isMobileMenuVisible: !prevState.isMobileMenuVisible
        }));
    }

	render() {
		const { isMobileMenuVisible } = this.state;
		let publicUrl = process.env.PUBLIC_URL + '/'
		var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		const { properties } = this.props;
		return (
			<div>
				<header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4--- mobile-style-container-top">
					<div className="ltn__header-top-area top-area-color-white">
						<div className="container  mobile-container-style">
							<div className="row" style={{ width: "100%" }}>
								<div className="col-md-7">
									<div className="ltn__top-bar-menu">
										<ul>
											<li><a href="mailto:#"><i className="icon-mail" /> info@lbi4rent.com</a></li>
											<li><a href="locations.html"><i className="icon-call" /> (609) 587-7671 or (609) 462-8330</a></li>
										</ul>
									</div>
								</div>
								<div className="col-md-5">
									<div className="top-bar-right text-end">
										<div className="ltn__top-bar-menu">
											<ul>
												<li>
													{/* ltn__language-menu */}
													<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
														<ul>
															<li><Link to="#" className="dropdown-toggle"><span className="active-currency">English</span></Link>
																<ul>
																	<li><Link to="#">English</Link></li>
																</ul>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<Social />
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
						<div className="container  mobile-container-style">
							<div className="row"  style={{ width: "100%" }}>
								<div className="col">
									<div className="site-logo-wrap">
										<div className="site-logo go-top">
											<Link to="/"><img src={IMAGE_BASE_URL + "/LBI4RENT-Images/logo-2.png"} alt="Logo" /></Link>
										</div>
									</div>
								</div>
								<div className="col header-menu-column menu-color-white">
									<div className="header-menu d-none d-xl-block go-top">
										<nav>
											<div className="ltn__main-menu">
												<ul>
												<li><Link to="/">Home</Link></li>
													<li><Link to="/about">About Us</Link></li>
													<li><Link to="/gallery">The Island</Link></li>
													<li className="menu-icon"><Link to="/properties">Properties</Link>
														<ul>
															{properties && properties?.map((property) => (
																<li key={property.id}>
																	<Link to={`/property-details/${property.id}`}>
																		{property.name}
																	</Link>
																</li>
															))}
														</ul>
													</li>
													<li className="special-link">
														<Link to="/contact">Contact</Link>
													</li>
												</ul>
											</div>
										</nav>
									</div>
								</div>
								<div className="col--- ltn__header-options ltn__header-options-2 ">
									{/* Mobile Menu Button */}
									<div className="mobile-menu-toggle d-xl-none">
										<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle" onClick={this.toggleMobileMenu}>
											<svg viewBox="0 0 800 600">
												<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
												<path d="M300,320 L540,320" id="middle" />
												<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
											</svg>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
				<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu" style={{ display: isMobileMenuVisible ? 'block' : 'none' }}>
					<div className="ltn__utilize-menu-inner ltn__scrollbar">
						<div className="ltn__utilize-menu-head">
							<div className="site-logo">
								<Link to="/"><img src={IMAGE_BASE_URL + "/LBI4RENT-Images/logo-2.png"} alt="Logo" /></Link>
							</div>
							<button className="ltn__utilize-close">×</button>
						</div>
						<div className="ltn__utilize-menu">
							<ul>
								<li><Link to="/">Home</Link></li>
								<li><Link to="/about">About</Link></li>
								<li><Link to="/gallery">The Island</Link></li>
								<li className="menu-icon"><Link to="/properties">Properties</Link>
									<ul>
										{properties && properties?.map((property) => (
											<li key={property.id}>
												<Link to={`/property-details/${property.id}`}>
													{property.name}
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li className="special-link">
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</div>
						<div className="ltn__social-media-2">
							<ul>
								<li><a href="https://www.facebook.com/Lbi4rent" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		properties: state.property.properties,
	};
};

export default connect(mapStateToProps)(NavbarV3);