import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Product_Details = () => {
    const { propertyId } = useParams();
    const properties = useSelector(state => state.property.properties); 
    const propertyIdNumber = parseInt(propertyId, 10);
    const property = properties.find(p => p.id === propertyIdNumber);
    var IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

    const imageUrl = property && property.medias && property.medias.length > 0 ? IMAGE_BASE_URL + property.medias[0].link : 'DEFAULT_IMAGE_URL';

    return <div>
        <Helmet>
            <title>{property ? `${property.name} in Long Beach Island, NJ | LBI4RENT` : 'Property Details | LBI4RENT'}</title>
            <meta name="description" content={property ? `${property.description.substring(0, 150)}...` : 'Explore detailed information about our properties in Long Beach Island, NJ.'} />
            <link rel="canonical" href={`https://www.lbinj4rent.com/#/property-details/${propertyId}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={property ? `${property.name} | LBI4RENT` : 'Property Details | LBI4RENT'} />
            <meta property="og:description" content={property ? property.description : 'Explore detailed information about our properties in Long Beach Island, NJ.'} />
            <meta property="og:url" content={`https://www.lbinj4rent.com/#/property-details/${propertyId}`} />
            <meta property="og:image" content={imageUrl} />
            <meta name="facebook:card" content="summary_large_image" />
            <meta name="facebook:title" content={property ? `${property.name} | LBI4RENT` : 'Property Details | LBI4RENT'} />
            <meta name="facebook:description" content={property ? property.description : 'Explore detailed information about our properties in Long Beach Island, NJ.'} />
            <meta name="facebook:image" content={imageUrl} />
        </Helmet>
        <Navbar />
        <PageHeader headertitle="Product Details" customclass="mb-0" />
        <ProductSlider key={propertyId} media={property?.medias} />
        {property && <ProductDetails property={property} />}
        <CallToActionV1 />
        <Footer />
    </div>
}

const mapStateToProps = state => {
    return {
        properties: state.property.properties,
    };
};

export default connect(mapStateToProps)(Product_Details);
