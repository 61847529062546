import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Page_header extends Component {

	render() {

		let HeaderTitle = this.props.headertitle;
		let publicUrl = process.env.PUBLIC_URL + '/'
		let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
		let CustomClass = this.props.customclass ? this.props.customclass : ''
		const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
		let image = "";
		switch (HeaderTitle) {
			case "About Us":
				image = IMAGE_BASE_URL + "/LBI4RENT-Images/surfer.JPG";
				break;
			case "Contact Us":
				image = IMAGE_BASE_URL + "/LBI4RENT-Images/flying_gull.JPG";
				break;
			case "Properties":
				image = IMAGE_BASE_URL + "/LBI4RENT-Images/lighthouse_forest.JPG";
				break;
			case "The Island":
				image = IMAGE_BASE_URL + "/LBI4RENT-Images/sunset_at_barnigate.JPG";
				break;
			default:
				image = IMAGE_BASE_URL + "/LBI4RENT-Images/IMG_5524.JPG";
				break;
		}

		return (

			<div className={"ltn__breadcrumb-area text-left bg-overlay-white-10 bg-image " + CustomClass} data-bs-bg={image}>
				<div className="container">
					<div className="row" style={{ width: "100%" }}>
						<div className="col-lg-12">
							<div className="ltn__breadcrumb-inner">
								<h1 className="page-title page-title-header-v3">{HeaderTitle == "Product Details" ? "Property Details" : HeaderTitle}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>


		)
	}
}


export default Page_header