import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const SortableItem = SortableElement(({ value }) => (
    <div style={{ cursor: 'grab' }}>
        <img src={value.link} alt="property" style={{
            width: '100px',
            height: '100px',
            objectFit: 'cover',
        }} />
    </div>
));

const SortableList = SortableContainer(({ items }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {items.map((item, index) => (
                <SortableItem key={`item-${item.id}`} index={index} value={item} />
            ))}
        </div>
    );
});

const ReorderImages = ({ medias, onSave }) => {
    // Ensure medias are sorted by their order before setting state
    const sortedMedias = medias.filter(media => media.order !== 0).sort((a, b) => a.order - b.order);
    const [items, setItems] = useState(sortedMedias);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newItems = arrayMoveImmutable(items, oldIndex, newIndex);
        setItems(newItems);
    };

    const handleSave = () => {
        // Adjust items to have the correct order before saving
        const adjustedItems = items.map((item, index) => ({
            ...item,
            order: index + 1 // Start order from 1
        }));
        onSave(adjustedItems);
    };

    return (
        <div style={{ marginBottom: "10px" }}>
            <SortableList items={items} onSortEnd={onSortEnd} axis="xy" />
            <button onClick={handleSave} className='btn theme-btn-1 btn-effect-1 text-uppercase' style={{ marginTop: "10px" }}>Save New Order</button>
        </div>
    );
};

export default ReorderImages;
